/// <reference types="@graphcommerce/next-ui/types" />

import {
  responsiveVal,
  breakpointVal,
  MuiButtonPill,
  MuiButtonResponsive,
  themeBaseDefaults,
  MuiSnackbar,
  MuiFabSizes,
  MuiSlider,
  MuiChip,
  MuiButtonInline,
  NextLink,
  svgIconStrokeWidth,
} from '@graphcommerce/next-ui'
import { createTheme, Theme, LinkProps } from '@mui/material'
import { Components, PaletteOptions } from '@mui/material/styles'
import { Poppins } from 'next/font/google'
import { ExtendedTypographyOptions } from './expand-theme'

const poppinsFont = Poppins({
  weight: ['400', '500', '600', '700'],
  subsets: ['latin'],
  style: ['normal'],
})

const lightPalette: PaletteOptions = {
  mode: 'light',
  primary: {
    main: '#5B8E2D',
    contrastText: '#ffffff',
    dark: '#56862a',
  },
  secondary: {
    main: '#4B4E54',
    light: '#f0f0f1',
    contrastText: '#ffffff',
    dark: '#262D33',
  },
  background: {
    default: '#F9F9FA',
    paper: '#ffffff',
    image: '#ffffff',
    shade: '#2E353B',
    dark: '#262D33',
    grey: '#f0f0f1',
  },
  divider: '#00000015',
  success: {
    main: '#01d26a',
  },
  info: {
    main: '#5B8E2D',
  },
  action: {
    hoverOpacity: 0.12,
  },
  text: {
    primary: '#4B4E54',
    secondary: '#B3B5B8',
    disabled: '#c5c6c8',
  },
}

export const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1536,
    xl: 1644,
    xxl: 2560,
  },
}

const fontSize = (from: number, to: number) =>
  breakpointVal('fontSize', from, to, breakpoints.values)

// Create a theme instance.
const createThemeWithPalette = (palette: PaletteOptions) =>
  createTheme({
    palette,
    ...themeBaseDefaults,
    breakpoints,
    shape: { borderRadius: 3 },
    typography: {
      fontFamily: poppinsFont.style.fontFamily,
      // @see docs typography.md
      h1: {
        ...fontSize(28, 54),
        fontWeight: 700,
        lineHeight: 1.48,
        letterSpacing: -0.7,
      },
      h2: {
        fontSize: responsiveVal(25, 40),
        fontWeight: 700,
        lineHeight: 1.5,
        letterSpacing: -0.3,
      },
      h2Overline: {
        ...fontSize(14, 20),
        fontWeight: 700,
        lineHeight: 1.75,
        color: lightPalette.text && lightPalette.text.secondary,
        textTransform: 'uppercase',
        letterSpacing: 1,
      },
      h3: {
        ...fontSize(20, 26),
        fontWeight: 700,
        lineHeight: 1.42,
      },
      h4: {
        ...fontSize(18, 22),
        fontWeight: 600,
        lineHeight: 1.72,
      },
      h5: {
        ...fontSize(17, 19),
        fontWeight: 600,
        lineHeight: 1.73,
      },
      h6: {
        ...fontSize(14, 17),
        fontWeight: 600,
        lineHeight: 1.76,
        letterSpacing: -0.5,
      },
      subtitle1: {
        ...fontSize(16, 17),
        fontWeight: 450,
        lineHeight: 1.76,
      },
      fontWeightBold: 600,
      body1: {
        ...fontSize(14, 16),
        lineHeight: 1.95,
      },
      subtitle2: {
        ...fontSize(14, 16),
        fontWeight: 500,
        lineHeight: 1.875,
      },
      body2: {
        ...fontSize(13, 14),
        lineHeight: 1.75,
      },
      caption: {
        // https://web.dev/font-size/#how-the-lighthouse-font-size-audit-fails
        ...fontSize(12, 13),
        lineHeight: 1.75,
      },
      button: {},
      overline: {
        // https://web.dev/font-size/#how-the-lighthouse-font-size-audit-fails
        fontSize: '12px',
        fontWeight: 700,
        letterSpacing: 1,
        lineHeight: 1.2,
        textTransform: 'uppercase',
      },
    } as ExtendedTypographyOptions,
    spacings: {
      xxs: responsiveVal(10, 16),
      xs: responsiveVal(12, 20),
      sm: responsiveVal(14, 30),
      md: responsiveVal(16, 50),
      lg: responsiveVal(24, 80),
      xl: responsiveVal(40, 100),
      xxl: responsiveVal(80, 140),
    },
    page: {
      horizontal: responsiveVal(10, 30),
      vertical: responsiveVal(10, 30),
    },
    appShell: {
      headerHeightSm: '46px',
      headerHeightMd: '104px',
      appBarHeightMd: '80px',
      appBarInnerHeightMd: '46px',
    },
  })

// todo: move most of the styles to the graphcommerce library while still allowing for extensibility.
const createOverrides = (theme: Theme): Components => ({
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        overflowY: 'scroll',
        '& .ProductListItem-root .Decimal': {
          fontSize: '0.65em',
          fontWeight: 600,
          verticalAlign: 'text-top',
        },
      },
      '#__next': {
        position: 'relative',
      },
    },
  },

  // https://mui.com/material-ui/guides/routing/#global-theme-link
  // https://www.graphcommerce.org/docs/framework/links
  MuiLink: { defaultProps: { component: NextLink } as LinkProps },
  MuiButtonBase: { defaultProps: { LinkComponent: NextLink } },

  MuiContainer: {
    variants: [
      {
        props: { disableGutters: false },
        style: {
          paddingLeft: theme.page.horizontal,
          paddingRight: theme.page.horizontal,
          [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.page.horizontal,
            paddingRight: theme.page.horizontal,
          },
        },
      },
    ],
  },

  MuiInputBase: {
    styleOverrides: {
      root: {
        fontSize: '16px', // https://css-tricks.com/16px-or-larger-text-prevents-ios-form-zoom/
      },
    },
  },

  MuiButton: {
    defaultProps: { color: 'inherit' },
    variants: [
      ...MuiButtonResponsive,
      ...MuiButtonPill,
      ...MuiButtonInline,
      {
        props: { variant: 'outlined' },
        style: {
          ...breakpointVal(
            'borderRadius',
            theme.shape.borderRadius * 2,
            theme.shape.borderRadius * 3,
            theme.breakpoints.values,
          ),
        },
      },
      {
        props: { variant: 'text' },
        style: { borderRadius: '99em' },
      },
      {
        props: { variant: 'inline' },
        style: { borderRadius: '99em' },
      },
      {
        props: { color: 'primary' },
        style: {
          '&:not(.Mui-disabled)': {
            boxShadow: 'none',
          },
        },
      },
      {
        props: { color: 'secondary' },
        style: {
          '&:not(.Mui-disabled)': {
            boxShadow: 'none',
          },
        },
      },
      {
        props: { variant: 'pill', color: 'inherit' },
        style: {
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.text.primary,

          '&:hover': {
            backgroundColor: theme.palette.background.grey,
          },
        },
      },
    ],
  },

  MuiFab: {
    styleOverrides: {
      root: {
        '&.MuiFab-default': {
          backgroundColor: theme.palette.background.paper,
          '&:hover': {
            backgroundColor: theme.palette.background.paper,
          },
          color: theme.palette.text.primary,
        },
      },
      colorInherit: {
        backgroundColor: 'inherit',
        '&:hover, &:focus': {
          backgroundColor: 'inherit',
        },
        boxShadow: 'none',
      },
      extended: {
        fontWeight: 400,
        textTransform: 'none',
      },
    },

    variants: [...MuiFabSizes],
  },

  MuiTextField: {
    styleOverrides: {
      root: {
        '& .MuiOutlinedInput-root': {
          ...breakpointVal(
            'borderRadius',
            theme.shape.borderRadius * 1.5,
            theme.shape.borderRadius * 2,
            theme.breakpoints.values,
          ),
        },
      },
    },
  },

  MuiListItemIcon: {
    styleOverrides: {
      root: {
        color: theme.palette.text.primary,
      },
    },
  },

  MuiChip: {
    variants: [...MuiChip],
  },

  MuiCheckbox: {
    styleOverrides: {
      colorPrimary: {
        color: theme.palette.text.disabled,
        '&.Mui-checked': {
          color: theme.palette.primary.main,
        },
      },
      colorSecondary: {
        color: theme.palette.text.disabled,
        '&.Mui-checked': {
          color: theme.palette.secondary.main,
        },
      },
    },

    variants: [
      {
        props: { size: 'medium' },
        style: {
          padding: 7,
        },
      },
    ],
  },

  MuiSwitch: {
    styleOverrides: {
      thumb: {
        boxShadow: theme.shadows[6],
      },
    },
  },

  MuiSnackbar: { variants: [...MuiSnackbar] },

  MuiAvatar: {
    styleOverrides: {
      colorDefault: {
        backgroundColor: theme.palette.text.disabled,
      },
    },
  },

  Money: {
    defaultProps: {
      round: true,
      formatOptions: {
        style: 'decimal',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      },
    },
  },

  MuiSlider: { variants: [...MuiSlider] },

  MuiCircularProgress: {
    defaultProps: {
      thickness: 2,
    },
  },

  IconSvg: {
    variants: [
      {
        props: {},
        style: {
          strokeWidth: svgIconStrokeWidth(28, 148, 2, 0.8),
        },
      },
    ],
  },
})

export const lightTheme = createThemeWithPalette(lightPalette)
lightTheme.components = createOverrides(lightTheme)
