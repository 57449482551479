// eslint-disable-next-line import/no-extraneous-dependencies
import { concatPagination } from '@apollo/client/utilities'
import { FramerNextPages } from '@graphcommerce/framer-next-pages'
import { GraphQLProvider, TypedTypePolicies } from '@graphcommerce/graphql'
import { GlobalHead } from '@graphcommerce/magento-store'
import { CssAndFramerMotionProvider, PageLoadIndicator } from '@graphcommerce/next-ui'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { AppProps } from 'next/app'
import { lightTheme } from '../components/theme'
import { I18nProvider } from '../lib/i18n/I18nProvider'
import '../global.css'

const policy: TypedTypePolicies = {
  MondayColumnValue: { keyFields: false },
  MondayBoard: {
    fields: {
      items_page: concatPagination(),
    },
  },
}

export default function ThemedApp(props: AppProps) {
  const { router } = props
  const { locale = 'en' } = router

  return (
    <CssAndFramerMotionProvider>
      <I18nProvider key={locale} locale={locale}>
        <GraphQLProvider {...props} policies={[policy]}>
          <ThemeProvider theme={lightTheme}>
            <GlobalHead />
            <CssBaseline />
            <PageLoadIndicator />
            <FramerNextPages {...props} />
          </ThemeProvider>
        </GraphQLProvider>
      </I18nProvider>
    </CssAndFramerMotionProvider>
  )
}
